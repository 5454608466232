import React, { useState, useEffect } from 'react'
import { Billboard, Pagination, SEO } from '@renderbus/common/components'
import { useLocation } from '@reach/router'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import {
  BackToTop,
  HeaderItem,
  NavigateMenu,
  NavigateMenuIcon,
  NavigateMenuItem,
  PreviousContain,
  RCHeader,
  RCTitle,
  SignButton,
} from './rc2024.atom'
import {
  Banner,
  Divider,
  EmptyWrap,
  Footer,
  FooterDivider,
  PaginationWrapper,
  Rc2024GalleryStyle,
  SearchMbWrap,
  SearchWrap,
  SeeMoreButton,
  SortItem,
  TabItem,
  Tabs,
  TabsWrap,
  WorkBannerButtonImg,
  WorkBigTitleImg,
  WorkHeader,
  WorkHeaderMb,
  WorkHeaderTitle,
  WorkHeaderTotal,
  WorkImg,
  WorkInfoContainer,
  WorkInfoWrap,
  WorkItem,
  WorkListWrap,
  WorkName,
  WorkSortWrap,
  WorkUserInfo,
  WorkView,
  WorkWrap,
} from './rc2024-gallery.atom'
import { graphql, useStaticQuery } from 'gatsby'
import SearchIcon from '../images/render-contest/work-search-icon.png'
import SearchIconMb from '../images/render-contest/work-search-icon-mb.png'
import PullIcon from '../images/render-contest/icon_pull.png'
import UpIcon from '../images/render-contest/icon_up.png'
import WorkAuthorAvatar from '../images/render-contest/work-author-avatar.png'
import WorkViewEye from '../images/render-contest/work-view-eye.png'
import PoliceIcon from '../../../common/components/footer/images/police.png'
import { allRcWorkList } from '../constant/rc-result/2024-result'
import WorkBigTitleMb from '../images/render-contest/work-big-title-mb.png'
import WorkBannerButtonMb from '../images/render-contest/work-banner-button-mb.png'
import WorkSearchEmpty from '../images/render-contest/work-search-empty.png'
import { useMedia } from 'use-media'

const headerItemList = [
  {
    label: '首页',
    link: '/rc2024.html#rc-top',
  },
  {
    label: '赛事详情',
    link: '/rc2024.html#rc-time',
  },
  {
    label: '赛事资料',
    link: '/rc2024.html#rc-content',
  },
  {
    label: '常见问题',
    link: '/rc2024.html#rc-design',
  },
  {
    label: '往届回顾',
  },
]

const mobileMenuItemList = [
  {
    label: '大赛引言',
    link: '/rc2024.html#rc-words',
  },
  {
    label: '大赛时间',
    link: '/rc2024.html#rc-time',
  },
  {
    label: '面向群体',
    link: '/rc2024.html#rc-group',
  },
  {
    label: '大赛奖品',
    link: '/rc2024.html#rc-award',
  },
  {
    label: '评委阵容',
    link: '/rc2024.html#rc-judges',
  },
  {
    label: '大赛内容',
    link: '/rc2024.html#rc-content',
  },
  {
    label: '设计说明',
    link: '/rc2024.html#rc-design',
  },
  {
    label: '关于大赛',
    link: '/rc2024.html#rc-about',
  },
]

const workTypeList = [
  { label: '全部作品', value: 'all' },
  { label: '入围作品', value: 'Shortlisted' },
]

function RenderContest2024() {
  const location = useLocation()
  const [showPrevious, setShowPrevious] = useState(false)
  const [workType, setWorkType] = useState('all')
  const [searchValue, setSearchValue] = useState('')
  const [sortType, setSortType] = useState('latest')
  const [pageNum, setPageNum] = useState(1)
  const [workList, setWorkList] = useState([])
  const [workNum, setWorkNum] = useState(0)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [allWorkList, setAllWorkList] = useState([])
  const [workListMb, setWorkListMb] = useState([])
  const isMobile = useMedia({ maxWidth: 768 })

  useEffect(() => {
    initData()
    initDataMb()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType, workType, pageNum])

  useEffect(() => {
    if (location.search) {
      let current = location.search.replace('?page=', '')
      setPageNum(Number(current))
    }
  }, [location.search])

  function handleSearch() {
    setPageNum(1)
    initData()
  }

  function handleSearchMb() {
    initDataMb()
  }

  function initData() {
    let params = {
      sortType: sortType === 'latest' ? 1 : 2,
      pageSize: 20,
      pageNum: pageNum,
    }
    if (workType === 'Shortlisted') {
      params.isShortlisted = 1
    }
    if (searchValue) {
      params.searchName = searchValue
    }
    axios({
      method: 'post',
      url: 'https://www.renderbus.com/api/rc/rc-result-2024/view-count',
      data: {
        ...params,
      },
    })
      .then((response) => {
        const { data } = response
        if (data) {
          const idList = data.data.map((i) => i.id)
          const filteredData = allRcWorkList
            .filter((item) => idList.includes(item.id))
            .sort(
              (a, b) =>
                data.data.findIndex((obj) => obj.id === a.id) -
                data.data.findIndex((obj) => obj.id === b.id),
            )
          const filterWorkList = filteredData.map((item) => {
            const correspondingItem = data.data.find((obj) => obj.id === item.id)
            return { ...item, viewCount: correspondingItem.viewCount }
          })
          setWorkList(filterWorkList)
          setWorkNum(data.total)
        } else {
          setWorkList([])
          return
        }
      })
      .catch((err) => {
        console.log(err)
        return
      })
      .finally(() => {
        return
      })
  }

  function seeMore() {
    let list = [...workListMb, ...allWorkList.slice(0, 20)]
    setWorkListMb(list)
    setAllWorkList(allWorkList.slice(20))
  }

  function initDataMb() {
    let params = {
      sortType: 1,
    }
    if (workType === 'Shortlisted') {
      params.isShortlisted = 1
    }
    if (searchValue) {
      params.searchName = searchValue
    }
    axios({
      method: 'post',
      url: 'https://www.renderbus.com/api/rc/rc-result-2024/view-count',
      data: {
        ...params,
      },
    })
      .then((response) => {
        const { data } = response
        if (data) {
          const idList = data.data.map((i) => i.id)
          const filteredData = allRcWorkList
            .filter((item) => idList.includes(item.id))
            .sort(
              (a, b) =>
                data.data.findIndex((obj) => obj.id === a.id) -
                data.data.findIndex((obj) => obj.id === b.id),
            )
          const filterWorkList = filteredData.map((item) => {
            const correspondingItem = data.data.find((obj) => obj.id === item.id)
            return { ...item, viewCount: correspondingItem.viewCount }
          })
          if (data.total > 20) {
            setWorkListMb(filterWorkList.slice(0, 20))
            setAllWorkList(filterWorkList.slice(20))
          } else {
            setWorkListMb(filterWorkList)
            setAllWorkList([])
          }
          setWorkNum(data.total)
        } else {
          return
        }
      })
      .catch((err) => {
        console.log(err)
        return
      })
  }

  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "render-contest/work-banner.png" }) {
        ...fluidImage
      }
      bannerMb: file(relativePath: { eq: "render-contest/work-mb-banner.png" }) {
        ...fluidImage
      }
    }
  `)

  return (
    <>
      <Helmet>
        <script>
          {`
            var _hmt = _hmt || [];
            (function() {
              var hm = document.createElement("script");
              hm.src = "https://hm.baidu.com/hm.js?695fad530c8ccd7ba407ec0f01da7817";
              var s = document.getElementsByTagName("script")[0];
              s.parentNode.insertBefore(hm, s);
            })();
          `}
        </script>
      </Helmet>
      <SEO
        title='参赛作品展 - 第三届瑞云3D渲染动画创作大赛｜灵感汇聚，创意接力'
        keywords='3D渲染大赛, 瑞云渲染大赛,瑞云渲染动画创作大赛'
        description='国内3D动画艺术垂直领域规模最大、奖金池最高的顶级赛事-第三届瑞云3D渲染动画创作大赛现已开启报名，赢现金奖品，就等你来！'
        shareImgSrc='//rayvision-render-works.oss-cn-hangzhou.aliyuncs.com/03/assets/banner.png'
        sharePostSlug='rc2024-gallery.html'
      />
      <Rc2024GalleryStyle />
      <RCHeader>
        {headerItemList.map((item) =>
          item.label === '往届回顾' ? (
            <HeaderItem
              key={item.label}
              className='previous-item'
              target={item.target}
              onClick={() => {
                setShowPrevious(!showPrevious)
              }}
              onMouseEnter={() => {
                setShowPrevious(true)
              }}
              onMouseLeave={() => {
                setShowPrevious(false)
              }}
            >
              {item.label}
              <img
                src={showPrevious ? UpIcon : PullIcon}
                alt=''
                onClick={() => {
                  setShowPrevious(!showPrevious)
                }}
              />
              <div className='interval'></div>
              {showPrevious && (
                <PreviousContain>
                  <a href='https://www.rayvision.com/rrc.html' rel='nofollow'>
                    第一届渲染大赛
                  </a>
                  <a href='https://www.renderbus.com/rc.html' rel='nofollow'>
                    第二届渲染大赛
                  </a>
                </PreviousContain>
              )}
            </HeaderItem>
          ) : (
            <HeaderItem key={item.label} href={item.link} rel={item.rel} target={item.target}>
              {item.label}
            </HeaderItem>
          ),
        )}
        <RCTitle className='hideInPC'>
          <p>第三届瑞云3D渲染动画创作大赛</p>
          <p>2023.11.24 - 2024.02.24</p>
        </RCTitle>
        <SignButton href='/rc2024' target='_blank' rel='nofollow'>
          参赛作品展
        </SignButton>
        <NavigateMenuIcon
          isActive={!showMobileMenu}
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        />
        <NavigateMenu isActive={showMobileMenu} showPCMenu={false}>
          <div className='menu-container'>
            {mobileMenuItemList.map((item) => (
              <NavigateMenuItem
                href={item.link}
                key={item.label}
                onClick={() => {
                  setShowMobileMenu(false)
                }}
                isActive={false}
              >
                {item.label}
              </NavigateMenuItem>
            ))}
            <BackToTop
              href='#rc-top'
              onClick={() => {
                setShowMobileMenu(false)
              }}
            >
              回到顶部
            </BackToTop>
          </div>
        </NavigateMenu>
      </RCHeader>
      <Banner>
        <WorkBigTitleImg>
          <img src={WorkBigTitleMb} alt='WorkBigTitleImg' />
        </WorkBigTitleImg>
        <WorkBannerButtonImg>
          <img src={WorkBannerButtonMb} alt='WorkBannerButtonImg' />
        </WorkBannerButtonImg>
        <Billboard
          fluid={[
            data.banner.childImageSharp.fluid,
            { ...data.bannerMb.childImageSharp.fluid, media: `(max-width: 780px)` },
          ]}
        />
      </Banner>
      <TabsWrap>
        <Tabs>
          {workTypeList.map((item) => (
            <TabItem
              key={item.value}
              active={workType === item.value}
              onClick={() => setWorkType(item.value)}
            >
              {item.label}
            </TabItem>
          ))}
        </Tabs>
        <SearchWrap>
          <input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
            placeholder='请输入选手名称或作品名称'
          />
          <img src={SearchIcon} onClick={() => handleSearch()} alt='' />
        </SearchWrap>
      </TabsWrap>
      <SearchMbWrap>
        <input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSearchMb()}
          placeholder='请输入选手名称或作品名称'
        />
        <img src={SearchIconMb} onClick={() => handleSearchMb()} alt='' />
      </SearchMbWrap>
      <WorkWrap>
        {isMobile && workListMb.length === 0 ? (
          <EmptyWrap>
            <img src={WorkSearchEmpty} alt='' />{' '}
            <span>
              没有找到相关内容
              <br />
              请尝试使用其他词汇搜索
            </span>
          </EmptyWrap>
        ) : !isMobile && workList.length === 0 ? (
          <EmptyWrap>
            <img src={WorkSearchEmpty} alt='' />
            <span>没有找到相关内容，请尝试使用其他词汇搜索</span>
          </EmptyWrap>
        ) : (
          <>
            <WorkHeader>
              <WorkHeaderTitle>
                全部作品 <WorkHeaderTotal>{workNum}</WorkHeaderTotal>
              </WorkHeaderTitle>
              <WorkSortWrap>
                <SortItem active={sortType === 'latest'} onClick={() => setSortType('latest')}>
                  最新
                </SortItem>
                <Divider type='vertical' height='14px' />
                <SortItem active={sortType === 'view'} onClick={() => setSortType('view')}>
                  浏览
                </SortItem>
              </WorkSortWrap>
            </WorkHeader>
            <WorkHeaderMb>
              <WorkHeaderTitle>全部作品({workNum})</WorkHeaderTitle>
            </WorkHeaderMb>
            <WorkListWrap>
              {(isMobile ? workListMb : workList).map((item, index) => (
                <WorkItem key={`pc_${index}`} to={`rc2024/details-id-${item.id}.html`}>
                  <WorkImg>
                    <img src={item.coverLink} key={item.id} alt='' />
                  </WorkImg>
                  <WorkInfoContainer>
                    <WorkName>{item.workName}</WorkName>
                    <WorkInfoWrap>
                      <WorkUserInfo>
                        <img src={WorkAuthorAvatar} alt='' />
                        {item.name}
                      </WorkUserInfo>
                      <WorkView>
                        <img src={WorkViewEye} alt='' />
                        {item.viewCount}
                      </WorkView>
                    </WorkInfoWrap>
                  </WorkInfoContainer>
                </WorkItem>
              ))}
            </WorkListWrap>
            <PaginationWrapper>
              <Pagination currentPage={pageNum} total={workNum ? Math.ceil(workNum / 20) : 1} />
            </PaginationWrapper>
            {allWorkList.length > 0 && (
              <SeeMoreButton onClick={() => seeMore()}>查看更多</SeeMoreButton>
            )}
          </>
        )}
      </WorkWrap>
      <FooterDivider type='horizontal' width='100%' borderColor='#E5E5E5' margin='60px 0 0' />
      <Footer>
        <p>
          <img src={PoliceIcon} alt='' />© 2024
          <a href='https://www.rayvision.com/' rel='nofollow'>
            深圳市瑞云科技股份有限公司
          </a>
          <a
            href='https://beian.miit.gov.cn/#/Integrated/index'
            target='_blank'
            rel='noopener noreferrer nofollow'
          >
            粤ICP备12028569号
          </a>
        </p>
        <p>中华人民共和国增值电信业务经营许可证编号：合字 B1-20200125</p>
      </Footer>
    </>
  )
}

export default RenderContest2024
